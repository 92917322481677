import { useEffect,useState } from "react";
import Payment from "./component/payment"; 
import { razorPayRedirectToContent } from "./component/utills/mobilehandlers";
function App() {
  useEffect(() => {
    const handlePageShow = (event:any) => {
      if (event.persisted) {
        // Page is restored from bfcache
        // Perform any action needed like re-fetching data or forcing a re-render
        console.log('Page was restored from bfcache');
        // Example: force re-render
        window.location.reload();
      }
    };

    window.addEventListener('pageshow', handlePageShow);

    return () => {
      window.removeEventListener('pageshow', handlePageShow);
    };
  }, []);

  const [loaded, setLoaded] = useState(false);
 
  function includeRazorpay() {

    const rzpScript = document.createElement('script');
    rzpScript.src = 'https://checkout.razorpay.com/v1/checkout.js';
    rzpScript.crossOrigin = 'anonymous';
    rzpScript.type = 'application/javascript';
    rzpScript.async = true;
    rzpScript.onload = () => {
      setLoaded(true);
  };
    document.body.appendChild(rzpScript);

    return rzpScript;
    
  }

  useEffect(()=>{
    includeRazorpay()
  })
 useEffect(()=>{
  const mobileView =
  new URLSearchParams(window.location.search).get("mobileContentPurchase") || undefined;
  if(mobileView){
    razorPayRedirectToContent('SUCCESS',localStorage.getItem('userId')||'')
  }
 },[])
  return (
    <div style={{width:'100%',height:'100%'}}>
     {loaded && <Payment/>}
    </div>
  );
}

export default App;
