import axios, { AxiosResponse } from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
export const API_URL_V2 = process.env.REACT_APP_API_URL_V2;

enum REST_METHODS {
  GET = "get",
  POST = "post",
  DELETE = "delete",
  PATCH = "patch",
  PUT = "put",
}
const apiCaller = async (
  {
    method,
    params,
    url,
    data,
    headers,
  }: {
    method: REST_METHODS;
    params?: Record<string, unknown>;
    url: string;
    data?: Record<string, unknown> | FormData;
    headers?: any;
  } = {
    method: REST_METHODS.GET,
    params: {},
    data: {},
    headers: {},
    url: "",
  }
) => {
  // headers = { ...headers, Authorization: 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NWRjNWY5NDVmNWIxZDA1NTljMDNhZjgiLCJzZXNzaW9uSWQiOiI2NWRjNWY5NjVmNWIxZDA1NTljMDNhZmEiLCJwaG9uZU51bWJlciI6IjgzNjM2NDY2MzQiLCJ2ZXJzaW9uIjo0LCJ0eXBlIjoiVVNFUiIsImV4cGlyZXNBdCI6IjIwMjQtMDItMjZUMTA6MDg6MjYuMDc3WiIsImlhdCI6MTcwODk0MTIwNiwiZXhwIjoxNzA5MDI3NjA2fQ.dZcvGp6PBqEW3ZKt9jk30IUAJTpMl-mbYTZsERaBboM' };
  if (method === REST_METHODS.GET) {
    return axios.get(url, { params, headers });
  }
  return axios({
    method: method,
    url,
    data,
    headers,
    params,
  });
};
const API = {
  validators: {
    checkSuccessCode: (response: AxiosResponse): boolean => {
      return Math.floor(response.status / 100) === 2;
    },
    matchStatusCode: (response: AxiosResponse, statusCode: number): boolean => {
      return response.status === statusCode ? true : false;
    },
  },
  user: {
    renewalStatus: ({ id }: { id: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/payment/gateway/renewal/${id}/status`,
      });
    },
    transitionStatus: ({ id }: { id: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL}/payment/gateway/transaction/${id}/status`,
      });
    },
    contentPassPurchase: ({
      clientId,
      clientContentId,
      type,
      userToken,
      paywallType,
      paywallId,
    }: {
      clientId: any;
      clientContentId: any;
      type: any;
      userToken: any;
      paywallType?:string;
      paywallId?:string

    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/gateway/order`,
        data: {
          clientId,
          clientContentId,
          type: type.toUpperCase(),
          paywallType,
          paywallId
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
    },
    stripeOrderCreate: ({
      clientId,
      clientContentId,
      type,
      userToken,
      redirectUrl,
    }: {
      clientId: any;
      clientContentId: any;
      type: any;
      userToken: any;
      redirectUrl: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/stripe/order`,
        data: {
          clientId,
          clientContentId,
          type: type.toUpperCase(),
          redirectUrl: redirectUrl,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
    },
    postRenewal: ({
      clientId,
      subscriptionId,
      tierId,
      type,
      contentDetails,
      manuallyRenewed,
      couponCode,
      consumeFreeTrial,
      anonId,
      userSourceData,
      userToken,
      paywallId,
      paywallType,
    }: {
      clientId: string;
      subscriptionId: string;
      tierId: string;
      type: string;
      contentDetails: any;
      manuallyRenewed?: boolean;
      couponCode?: string;
      consumeFreeTrial?: boolean;
      anonId?: string;
      userSourceData?: string;
      userToken?: string;
      paywallId?:string;
      paywallType?:string
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/gateway/renewal`,
        data: {
          clientId,
          subscriptionId,
          tierId,
          type,
          contentDetails,
          manuallyRenewed,
          couponCode,
          consumeFreeTrial,
          anonId,
          paywallType,
          paywallId,
          ...(userSourceData && { userSourceData }),
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
    },
  },
  payment: {
    authorizationCode: ({
      clientId,
      authzCode,
      rid,
    }: {
      clientId: string;
      authzCode: string;
      rid?:string
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/authorization/login?clientId=${clientId}&rid=${rid}`,
        headers: {
          Authorization: authzCode,
        },
      });
    },
    refreshTokenForSubscription: ({ authzCode }: { authzCode: string }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/authorization/refresh`,
        headers: {
          Authorization: authzCode,
        },
      });
    },
    pgIntegration: ({
      clientId,
      clientContentId,
      type,
      tierId,
      subscriptionId,
      referenceId,
      offerIds,
      viewId,
      anonId,
      purchaseLocation,
      consumeFreeTrial,
      manuallyRenewed,
      couponCode,
      paywallId,
      firstTimeLogin,
      landingPageId,
      utmParameters,
      popupId,
      contentType,
      userDateTime,
      contentDetails,
      paywallType,
      userSourceData,
      successFrontendUrl,
      failureFrontendUrl,
      accessToken,
      amount,
      currency
    }: {
      clientId: string;
      clientContentId?: string;
      type?: string;
      tierId?: string;
      subscriptionId?: string;
      referenceId?: string;
      offerIds?: any;
      viewId?: string;
      anonId?: string;
      purchaseLocation?: string;
      consumeFreeTrial?: boolean;
      manuallyRenewed?: boolean;
      couponCode?: string;
      paywallId?: string;
      firstTimeLogin?: boolean;
      landingPageId?: string;
      utmParameters?: any;
      popupId?: string;
      contentType?: string;
      userDateTime?: string;
      contentDetails?: any;
      paywallType?: string;
      userSourceData?: string;
      successFrontendUrl?: string;
      failureFrontendUrl?: string;
      accessToken?: string;
      amount?:number;
      currency?:String
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/gateway/order`,
        data: {
          clientId,
          clientContentId,
          type,
          tierId,
          subscriptionId,
          referenceId,
          offerIds,
          viewId,
          anonId,
          purchaseLocation,
          consumeFreeTrial,
          manuallyRenewed,
          couponCode,
          paywallId,
          firstTimeLogin: firstTimeLogin ? 1 : 0,
          landingPageId,
          utmParameters,
          popupId,
          contentType,
          userDateTime,
          contentDetails,
          paywallType,
          successFrontendUrl,
          failureFrontendUrl,
          amount,
          currency,
          ...(userSourceData && { userSourceData }),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    renewalSubscriptionOrderForStripe: ({
      clientId,
      subscriptionId,
      tierId,
      type,
      contentDetails,
      manuallyRenewed,
      couponCode,
      consumeFreeTrial,
      anonId,
      userSourceData,
      accessToken,
      redirectUrl,
    }: {
      clientId: string;
      subscriptionId: string;
      tierId: string;
      type: string;
      contentDetails: any;
      manuallyRenewed?: boolean;
      couponCode?: string;
      consumeFreeTrial?: boolean;
      anonId?: string;
      userSourceData?: string;
      accessToken?: string;
      redirectUrl: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/stripe/renewal`,
        data: {
          clientId,
          subscriptionId,
          tierId,
          type,
          contentDetails,
          manuallyRenewed,
          couponCode,
          consumeFreeTrial,
          anonId,
          redirectUrl,
          ...(userSourceData && { userSourceData }),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    subscriptionOrderForStripe: ({
      clientId,
      clientContentId,
      type,
      tierId,
      subscriptionId,
      referenceId,
      offerIds,
      viewId,
      anonId,
      purchaseLocation,
      consumeFreeTrial,
      manuallyRenewed,
      couponCode,
      paywallId,
      firstTimeLogin,
      landingPageId,
      utmParameters,
      popupId,
      contentType,
      userDateTime,
      contentDetails,
      paywallType,
      userSourceData,
      successFrontendUrl,
      failureFrontendUrl,
      accessToken,
      redirectUrl,
      amount,
      currency,
    }: {
      clientId: string;
      clientContentId?: string;
      type?: string;
      tierId?: string;
      subscriptionId?: string;
      referenceId?: string;
      offerIds?: any;
      viewId?: string;
      anonId?: string;
      purchaseLocation?: string;
      consumeFreeTrial?: boolean;
      manuallyRenewed?: boolean;
      couponCode?: string;
      paywallId?: string;
      firstTimeLogin?: boolean;
      landingPageId?: string;
      utmParameters?: any;
      popupId?: string;
      contentType?: string;
      userDateTime?: string;
      contentDetails?: any;
      paywallType?: string;
      userSourceData?: string;
      successFrontendUrl?: string;
      failureFrontendUrl?: string;
      accessToken?: string;
      redirectUrl?: string;
      amount?:number;
      currency?:string

    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/stripe/order`,
        data: {
          clientId,
          clientContentId,
          type,
          tierId,
          subscriptionId,
          referenceId,
          offerIds,
          viewId,
          anonId,
          purchaseLocation,
          consumeFreeTrial,
          manuallyRenewed,
          couponCode,
          paywallId,
          firstTimeLogin: firstTimeLogin ? 1 : 0,
          landingPageId,
          utmParameters,
          popupId,
          contentType,
          userDateTime,
          contentDetails,
          paywallType,
          successFrontendUrl,
          failureFrontendUrl,
          redirectUrl,
          amount,
          currency,
          ...(userSourceData && { userSourceData }),
        },
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    CallRouting: ({
      clientId,
      accessToken,
    }: {
      clientId: string;
      accessToken: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/payment/gateway-config?clientId=${clientId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    userDetails: ({
      clientId,
      accessToken,
    }: {
      clientId: string;
      accessToken: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/authorization/prefill-user-details?clientId=${clientId}`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    },
    PaypalOrderForRenewal: ({ callPaypalPrams }: { callPaypalPrams: any }) => {
      console.log(callPaypalPrams);
      
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/paypal/renewal`,
        data: callPaypalPrams,
        headers: {
          Authorization: `Bearer ${callPaypalPrams.accessToken}`,
        },
      });
    },
    CallPaypal: ({ callPaypalPrams }: { callPaypalPrams: any }) => {
      console.log(callPaypalPrams);
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/paypal/order`,
        data: callPaypalPrams,
        headers: {
          Authorization: `Bearer ${callPaypalPrams.accessToken}`,
        },
      });
    },
    abortTransaction: ({
      type,
      id,
      event,
      token,
    }: {
      type: string;
      id: string;
      event: any;
      token: string;
    }) => {
      return apiCaller({
        method: REST_METHODS.POST,
        url: `${API_URL_V2}/payment/abort`,
        data: {
          id,
          type,
          event,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  },
  content: {
    convertCurrency: ({
      from,
      to,
      amount,
    }: {
      from: string;
      to: string;
      amount: number;
    }) => {
      return apiCaller({
        method: REST_METHODS.GET,
        url: `${API_URL_V2}/content/convert-currency`,
        params: { from, to, amount },
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJZCI6IjVmOTJhNjIwMTMzMzJlMGY2Njc3OTRkYyIsImVtYWlsIjoiY2xpZW50MUBzZWVkLmNvbSIsInZlcnNpb24iOjEsInR5cGUiOiJDTElFTlQiLCJpYXQiOjE3MDgwMTI3MTYsImV4cCI6MTcwODA5OTExNn0.N_jqvB9X82y-ZXEUZAiZEZWYM_ZOMyYm2rAVd271DPQ",
        },
      });
    },
  },
};

export default API;
