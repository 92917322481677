import React, { useEffect, useState, useCallback, useRef } from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import API from "../../Api/api";
import paymentStyle from "../payment.module.css";
import Loader from "../loader";

export enum SUBSCRIPTION_ROUTES {
  REVIEW = "/review/subscription",
  THANKYOU = "/thankyou",
}

interface PaypalPaymentProps {
  accessToken: string;
  isContentPass?: boolean;
  publishableKey: string;
}

interface CreateOrderData {
  redirectUrl: string;
  transactionId: string;
}

const PaypalPayment: React.FC<PaypalPaymentProps> = ({
  accessToken,
  isContentPass,
  publishableKey,
}) => {
  const [initialOptions, setInitialOptions] = useState<any>(null);
  const [gatewayPublishableKey, setGatewayPublishableKey] = useState<
    string | null
  >(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [createOrderData, setCreateOrderData] =
    useState<CreateOrderData | null>(null);
  const isInitialized = useRef(false); // Track initialization

  const getSubscriptionContentDetails = (): any | null => {
    const subsDetailsParam = new URLSearchParams(window.location.search).get(
      "subsDetails"
    );
    if (!subsDetailsParam) return null;
    try {
      return JSON.parse(decodeURIComponent(window.atob(subsDetailsParam as string)));
    } catch (e) {
      console.error("Error parsing subscription content details:", e);
      return null;
    }
  };

  const subscriptionContentDetails = getSubscriptionContentDetails();

  const initializeCheckout = useCallback(async () => {
    if (!subscriptionContentDetails) {
      setError("Invalid subscription content details");
      setLoading(false);
      return;
    }

    subscriptionContentDetails.accessToken = accessToken;
    const modifyRedirectUrl = new URL(
      subscriptionContentDetails.successFrontendUrl
    );
    modifyRedirectUrl.searchParams.append(
      "viewId",
      subscriptionContentDetails.viewId
    );
    modifyRedirectUrl.searchParams.append(
      "clientId",
      subscriptionContentDetails.clientId
    );
    modifyRedirectUrl.searchParams.append(
      "clientContentId",
      subscriptionContentDetails.clientContentId
    );

    subscriptionContentDetails.redirectUrl = modifyRedirectUrl.toString();
    subscriptionContentDetails.accessToken = accessToken;
    subscriptionContentDetails.successFrontendUrl =
      modifyRedirectUrl.toString();

    const postRenewalPrams = {
      clientId: subscriptionContentDetails.clientId,
      subscriptionId: subscriptionContentDetails.subscriptionId,
      tierId: subscriptionContentDetails.tierId,
      type: "SUBSCRIPTION",
      contentDetails: subscriptionContentDetails,
      couponCode: subscriptionContentDetails.couponCode,
      consumeFreeTrial: subscriptionContentDetails.consumeFreeTrial,
      anonId: subscriptionContentDetails.anonId,
      accessToken: accessToken,
      redirectUrl: subscriptionContentDetails.redirectUrl,
      successFrontendUrl: subscriptionContentDetails.successFrontendUrl,
      amount: subscriptionContentDetails.amount,
      currency: subscriptionContentDetails.currency,
    };

    try {
      let paypalOrderResponse;
      if (subscriptionContentDetails.renewSubscription) {
        paypalOrderResponse = await API.payment.PaypalOrderForRenewal({
          callPaypalPrams: postRenewalPrams,
        });
      } else {
        paypalOrderResponse = await API.payment.CallPaypal({
          callPaypalPrams: subscriptionContentDetails,
        });
      }

      if (!API.validators.checkSuccessCode(paypalOrderResponse)) {
        throw new Error("Unable to create PayPal order");
      }

      const { paypalRedirectUrl, transactionId, publishableKey } =
        paypalOrderResponse.data.data;
      setGatewayPublishableKey(publishableKey);
      setCreateOrderData({ redirectUrl: paypalRedirectUrl, transactionId });
      window.location.href = paypalRedirectUrl;
    } catch (err) {
      console.error("Error creating PayPal order:", err);
      setError("Error creating PayPal order");
      setLoading(false);
    }
  }, [accessToken, subscriptionContentDetails]);

  useEffect(() => {
    if (!isInitialized.current && accessToken) {
      initializeCheckout();
      isInitialized.current = true; // Mark as initialized
    }
  }, [accessToken, initializeCheckout]);

  useEffect(() => {
    if (gatewayPublishableKey) {
      setInitialOptions({ "client-id": gatewayPublishableKey });
    }
  }, [gatewayPublishableKey]);

  return (
    <>
      {initialOptions ? (
        <PayPalScriptProvider options={initialOptions}>
          <div className={paymentStyle["main-container"]}>
            {loading && <Loader />}
            {error && (
              <div className={paymentStyle["error-message"]}>{error}</div>
            )}
          </div>
        </PayPalScriptProvider>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default PaypalPayment;
