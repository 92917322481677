// Loader.tsx
import React from "react";
import paymentStyle from "./payment.module.css";

const Loader = () => {
  return (
    <div className={paymentStyle["loader-container"]}>
      <div id={paymentStyle["loader"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p className={paymentStyle["loader-text"]}>
        Initializing your payment process. Please don't refresh the page.
      </p>
    </div>
  );
};

export default Loader;
